import clsx from "clsx";
import { Image } from "components/ui/Image/Image";
import { paths } from "configs/paths";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface Props {
  className?: string;
}

export const Logo: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation("layouts");

  return (
    <Link className={clsx("flex items-center", className)} to={paths.index}>
      <Image alt={t("logo.BNPL")} className="w-20 mr-1" src="/images/bnpl.svg" />
      <div className="flex flex-col justify-center font-bold text-2xl">
        <h5>{t("logo.BNPL")}</h5>
        <h5>{t("logo.pay")}</h5>
      </div>
    </Link>
  );
};
